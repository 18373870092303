<template>
  <div class="wrapper front-page">
    <header class="header">
      <div class="container">
        <div class="row align-items-md-center">
          <div class="col-auto h-left">
            <div class="logo">
              <a><img src="@/assets/landing/logo.png" alt="logo"/></a>
            </div>
          </div>
          <div class="col h-right text-right">
            <div class="row align-items-center justify-content-end">
              <div class="col-12 col-md-auto order-3 order-md-2">
                <div>
                  <VBtn @click="onClickDock" plain color="white" small>
                    Документація
                  </VBtn>
                </div>
              </div>
              <div class="col-12 col-md-auto order-3 order-md-2">
                <div class="phone">
                  <a href="tel:+380681803018">+38 068-18-030-18</a>
                </div>
              </div>

              <div class="col-12 col-md-auto order-1 order-md-4">
                <div class="languages-widget-block">
                  <div
                    class="wpml-ls-sidebars-languages-widget wpml-ls wpml-ls-legacy-list-horizontal"
                  >
                    <ul>
                      <li
                        v-for="language in languages"
                        :key="language.key"
                        class="wpml-ls-slot-languages-widget wpml-ls-item wpml-ls-item-ru wpml-ls-first-item wpml-ls-item-legacy-list-horizontal"
                        :class="{
                          'wpml-ls-current-language': currentLanguage.key === language.key
                        }"
                        style="color:#ffff"
                      >
                        <a class="wpml-ls-link"
                          ><span class="wpml-ls-display" @click="switchLanguage(language)">{{
                            language.title
                          }}</span></a
                        >
                      </li>
                      <!-- <li
                        class="wpml-ls-slot-languages-widget wpml-ls-item wpml-ls-item-uk wpml-ls-current-language wpml-ls-last-item wpml-ls-item-legacy-list-horizontal"
                      >
                        <a class="wpml-ls-link"><span class="wpml-ls-native">UA</span></a>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="!authenticated" class="col-12 col-md-auto order-4">
                <div class="register">
                  <VBtn @click="$router.push({ path: '/register' }).catch(() => {})" color="white" text class="text-transform-none br-20" small>
                   {{$t('auth.btnRegister')}}
                  </VBtn>
                </div>
              </div>
              <div class="col-12 col-md-auto order-5">
                <div class="order mt-md-auto mt-0">
                  <a @click="$router.push({ path: '/' }).catch(() => {})" class="white--text">
                    {{ $t("landing.personal_cabinet") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="main">
      <div class="container">
        <div class="for-title">
          <div class="ns-title">
            <div>{{ $t("landing.title") }}</div>
            <br class="d-md-none" />{{ $t("landing.sub_title") }}
          </div>
          <div class="min-title big">
            {{ $t("landing.send_your_client") }} <br />
            {{ $t("landing.send_your_client2") }}
          </div>
        </div>
        <div class="order">
          <div class="plain">
            <img src="@/assets/landing/main-plain.png" alt="plain" />
          </div>
          <a @click="scrollTo('footer')" v-html="$t('landing.btn.get_consultant')"> </a>
        </div>
        <div class="m-bottom">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-4 col-lg">
              <div class="m-block">
                <div class="icon">
                  <img src="@/assets/landing/m1.png" alt="icon" />
                </div>
                <p class="min">{{ $t("landing.send_without_queue") }}</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg">
              <div class="m-block">
                <div class="icon">
                  <img src="@/assets/landing/m2.png" alt="icon" />
                </div>
                <p class="min">{{ $t("landing.contain_your_items") }}</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg">
              <div class="m-block">
                <div class="icon">
                  <img src="@/assets/landing/m3.png" alt="icon" />
                </div>
                <p class="min">{{ $t("landing.opertaions_with_ur_items") }}</p>
              </div>
            </div>

            <div class="col-6 col-sm-4 col-lg">
              <div class="m-block">
                <div class="icon">
                  <img src="@/assets/landing/m4.png" alt="icon" />
                </div>
                <p class="min">{{ $t("landing.manage_from_devices") }}</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg">
              <div class="m-block">
                <div class="icon">
                  <img src="@/assets/landing/m5.png" alt="icon" />
                </div>
                <p class="min">{{ $t("landing.crm_system") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="what">
      <div class="line bt">
        <img src="@/assets/landing/black-line.png" alt="line" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7 col-lg-8">
            <div class="w-left">
              <div class="ns-title d-md-none">
                {{ $t("landing.what_is") }}
                <span class="fr-ln">
                  {{ $t("landing.online_dep") }}
                  <span class="plain">
                    <img
                      class="d-none d-md-inline"
                      src="@/assets/landing/what-plain.png"
                      alt="plain"
                    />
                    <img
                      class="d-md-none"
                      src="@/assets/landing/wh-plain-mob.png"
                      alt="plain"
                    /> </span
                ></span>
              </div>
              <div class="video">
                <div class="embed-responsive embed-responsive-16by9">
                  <video
                    v-if="currentLanguage.key === 'uk'"
                    poster="@/assets/landing/video/video-ukr.png"
                    controls
                    type="video/mp4"
                    src="@/assets/landing/video/video-ukr.mp4"
                    class="embed-responsive-item"
                  ></video>
                  <video
                    v-else
                    poster="@/assets/landing/poster.jpg"
                    controls
                    type="video/mp4"
                    src="@/assets/landing/video/video.mp4"
                    class="embed-responsive-item"
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-4">
            <div class="w-right">
              <div class="ns-title d-none d-md-block">
                {{ $t("landing.what_is") }}
                <span class="fr-ln"
                  >{{ $t("landing.online_dep") }}
                  <span class="plain"
                    ><img
                      src="@/assets/landing/what-plain.png"
                      alt="plain"
                      style="padding-top:10px;"/></span
                ></span>
              </div>
              <div class="text">
                <div class="min-title">
                  <br />
                  <span>{{ $t("landing.up_business") }}</span
                  >,<br />{{ $t("landing.save_from_worries") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="produce">
      <div class="container">
        <div class="ns-title">
          <div>
            {{ $t("landing.we") }}
            <div class="logo">
              <img src="@/assets/landing/logo2.png" alt="logo" />
            </div>
          </div>
          {{ $t("landing.apply") }}
        </div>
        <div class="p-row">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-6 col-lg-4 order-3 order-md-1">
              <div class="p-block pp-1">
                <div class="order">
                  <div class="icon">
                    <img src="@/assets/landing/ww1.jpg" alt="icon" />
                  </div>
                  <a class="btn collapsed" data-toggle="collapse" href="#pp-1">
                    <span class="min-title"
                      >{{ $t("landing.call-center") }}<br />
                      {{ $t("landing.and_crm") }}</span
                    >
                  </a>
                </div>
                <div class="collapse d-md-block" id="pp-1">
                  <div class="card">
                    <div class="list">
                      <p v-html="$t('landing.fast_outgoing_calls')"></p>
                      <p v-html="$t('landing.hight_upprove')"></p>
                      <p v-html="$t('landing.record_conversation')"></p>
                      <p v-html="$t('landing.crm_access')"></p>
                      <p v-html="$t('landing.crm_access')"></p>
                      <p v-html="$t('landing.call_center_price')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 order-2">
              <div class="p-block pp-2">
                <div class="order">
                  <div class="icon">
                    <img src="@/assets/landing/ww2.jpg" alt="icon" />
                  </div>
                  <a class="btn collapsed" data-toggle="collapse" href="#pp-2">
                    <span class="min-title" v-html="$t('landing.process_automatiosation')"></span>
                  </a>
                </div>
                <div class="collapse d-md-block" id="pp-2">
                  <div class="card">
                    <div class="list">
                      <p v-html="$t('landing.get_items')"></p>
                      <p v-html="$t('landing.check_all')"></p>
                      <p v-html="$t('landing.check_all')"></p>
                      <p v-html="$t('landing.set_bar_codes')"></p>
                      <p v-html="$t('landing.contain_items')"></p>
                      <p v-html="$t('landing.check_items')"></p>
                      <p v-html="$t('landing.post_prepare')"></p>
                      <p v-html="$t('landing.free_return')"></p>
                      <p v-html="$t('landing.forwarding_of_goods')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 order-1 order-md-3">
              <div class="p-block pp-3">
                <div class="order">
                  <div class="icon">
                    <img src="@/assets/landing/ww3.jpg" alt="icon" />
                  </div>
                  <a class="btn collapsed" data-toggle="collapse" href="#pp-3">
                    <span class="min-title" v-html="$t('landing.packagine')"></span>
                  </a>
                </div>
                <div class="collapse show" id="pp-3">
                  <div class="card">
                    <div class="list">
                      <p v-html="$t('landing.full_complect')"></p>
                      <p>
                        <span v-html="$t('landing.prompt_dispatch')"> </span>
                        <span class="icon ins">
                          <img src="@/assets/landing/ins.png" alt="icon" />
                        </span>
                      </p>
                      <p v-html="$t('landing.print_invoice')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="forma fr-circle black">
          <div class="min-title" v-html="$t('landing.get_consultation')"></div>
          <form action="zakaz.php" method="post">
            <div class="inputblock">
              <input
                :placeholder="$t('landing.form.name_surname')"
                name="name"
                type="text"
                required
                v-model="userData.name"
                @keypress="validationForName($event)"
                @paste="validationForName($event)"
              />
            </div>
            <div class="inputblock">
              <div class="input-box">
                <span class="prefix">+38</span>
                <input
                  :placeholder="$t('landing.form.phone')"
                  name="phone"
                  maxlength="10"
                  type="tel"
                  required
                  v-model="userData.phone"
                  @keypress="validationForPhoneNumber($event)"
                  @paste="validationForPhoneNumber($event)"
                />
              </div>
            </div>
            <div class="order">
              <button
                class="send"
                type="button"
                @click="createJoinRequest()"
                :disabled="loadingRequest"
              >
                {{ $t("landing.btn.send") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <section class="tariffs">
      <!-- <div class="line bt">
        <img src="@/assets/landing/black-line.png" alt="line" />
      </div> -->
      <div class="container">
        <div class="plane-tariff">
          <img class="tariff-img" src="@/assets/landing/plane-tariff.svg" alt="plane-tariff" />
        </div>
        <div class="t-block">
          <div class="t-block-container">
            <div class="t-header">
              <div class="t-header-title">
                {{ $t("landing.simple_tariff") }}
              </div>
              <div class="t-header-subtitle">
                {{ $t("landing.tarrif_list.save_products_easy") }}
              </div>
            </div>
            <div class="t-content">
                <div class="t-content-block">
                  <div class="block-title">
                    {{ $t("landing.tarrif_list.order_processing") }}
                  </div>
                    <div class="block-list">
                      <div class="list-text">
                        <div class="text-header">
                          <p>
                            {{ planItem.price_delivery + " " + $t("landing.tarrif_list.hrn")}}
                          </p>
                        </div>
                        <div class="text-content">
                          <p>
                            {{ $t("landing.tarrif_list.shipping_your_order") }}
                          </p>
                        </div>
                      </div>
                      <div class="list-text">
                        <div class="divider-top"></div>
                        <div class="text-header">
                          <p>
                            {{ $t("landing.tarrif_list.free") }}
                          </p>
                        </div>
                        <div class="text-content">
                          <p>
                            {{ $t("landing.tarrif_list.each_additional_product") }}
                          </p>
                        </div>
                        <div class="divider-bottom"></div>
                      </div>
                      <div class="list-text">
                        <div class="text-header">
                          <p>
                            {{ $t("landing.tarrif_list.free") }}
                          </p>
                        </div>
                        <div class="text-content">
                          <p>
                            {{ $t("landing.tarrif_list.each_additional_kh") }}
                          </p>
                        </div>
                      </div>
                  </div>
                </div>

              <div class="t-content-block">
                <div class="block-title">
                  {{ $t("landing.tarrif_list.reception_and_storage") }}
                </div>
                <div class="block-list">
                  <div class="list-text">
                    <div class="text-header">
                      <p>
                         {{ planItem.price_month_meter + " " + $t("landing.tarrif_list.hrn")}} м&#179; {{ $t("landing.tarrif_list.per_month") }}
                      </p>
                    </div>
                    <div class="text-content">
                      <p>
                        {{ $t("landing.tarrif_list.reception_and_storage_on_depot") }}
                      </p>
                    </div>
                  </div>
                  <div class="list-text">
                    <div class="divider-top"></div>
                    <div class="text-header">
                      <p>
                        {{ $t("landing.tarrif_list.free") }}
                      </p>
                    </div>
                    <div class="text-content">
                      <p>
                        {{ $t("landing.tarrif_list.acceptance_of_the_goods") }}
                      </p>
                    </div>
                    <div class="divider-bottom"></div>
                  </div>
                  <div class="list-text">
                    <div class="text-header">
                      <p>
                        {{ $t("landing.tarrif_list.free") }}
                      </p>
                    </div>
                    <div class="text-content">
                      <p>
                        {{ $t("landing.tarrif_list.checking_the_product") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            <div class="t-content-block">
              <div class="block-title">
                {{ $t("landing.tarrif_list.additional_services") }}
              </div>
              <div class="block-list">
                <div class="list-text">
                  <div class="text-header">
                    <p>
                      {{ $t("landing.tarrif_list.free") }}
                    </p>
                  </div>
                  <div class="text-content">
                    <p>
                      {{ $t("landing.tarrif_list.processing_returns") }}
                    </p>
                  </div>
                </div>
                <div class="list-text">
                  <div class="divider-top"></div>
                  <div class="text-header">
                    <p>
                      {{ $t("landing.tarrif_list.free") }}
                    </p>
                  </div>
                  <div class="text-content">
                    <p>
                      {{ $t("landing.tarrif_list.checking_returns_for_completeness") }}
                    </p>
                  </div>
                  <div class="divider-bottom"></div>
                </div>
                <div class="list-text">
                  <div class="text-header">
                    <p>
                      {{ $t("landing.tarrif_list.free") }}
                    </p>
                  </div>
                  <div class="text-content">
                    <p>
                      {{ $t("landing.tarrif_list.adding_a_check") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
           </div>
            <div class="t-button">
            <div class="block-button">
              <div class="btn-text">
                <a @click="scrollTo('footer')" v-html="$t('landing.btn.get_consultant')"> </a>
              </div>
            </div>
           </div>
          </div>
        </div>
      </div>
    </section>

    <section class="integration">
      <!-- <div class="line bt">
        <img src="@/assets/landing/black-line.png" alt="line" />
      </div> -->
      <div class="container">
        <div class="int-block">
          <div class="int-block-container">
            <div class="int-header">
              <div class="int-header-title">
                {{ $t("landing.tarrif_list.integration_with") }} {{ 'NewSend' }}
              </div>
              <div class="int-header-subtitle">
                {{ $t("landing.tarrif_list.you_can_use_our_convenient_CRM") }}
              </div>
            </div>
            <div class="int-content">
                <div class="int-logo-block">
                  <div class="int-header-img">
                    <img src="@/assets/landing/new-send-logo-icon.svg" alt="new-send-logo"/>
                  </div>
                </div>

              <div class="t-button">
                <div class="block-button">
                  <div class="btn-text">
                    <img src="@/assets/landing/integration-plus-icon.svg" alt="integration-plus"/>
                  </div>
                </div>
              </div>

              <div class="int-content-block">
                <div class="section">
                  <div class="subsection">
                    <div class="subsection-img">
                      <img src="@/assets/landing/ukr-poshta-icon.svg" alt="ukr-poshta"/>
                    </div>
                    <div class="subsection-img">
                      <img src="@/assets/landing/lp-crm-biz-icon.svg" alt="lp-crm" />
                    </div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-img">
                      <img src="@/assets/landing/nova-poshta-icon.svg" alt="nova-poshta" />
                    </div>
                    <div class="subsection-img">
                      <img src="@/assets/landing/rozetka-icon.svg" alt="rozetka-icon" />
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="subsection">
                    <div class="subsection-img">
                      <img src="@/assets/landing/shopify-icon.svg" alt="shopify" />
                    </div>
                    <div class="subsection-img">
                      <img src="@/assets/landing/tilda-icon.svg" alt="tilda-icon" />
                    </div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-img">
                      <img src="@/assets/landing/amo-crm-icon.svg" alt="amo-crm" />
                    </div>
                    <div class="subsection-img">
                      <img src="@/assets/landing/retail-crm-icon.svg" alt="retail-crm" />
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="subsection">
                    <div class="subsection-img">
                      <img src="@/assets/landing/horoshop-icon.svg" alt="horoshop" />
                    </div>
                    <div class="subsection-img">
                      <img src="@/assets/landing/prom-icon.svg" alt="prom" />
                    </div>
                  </div>
                  <div class="subsection">
                    <div class="subsection-img">
                      <img src="@/assets/landing/key-crm-icon.svg" alt="key-crm" />
                    </div>
                    <div class="subsection-img">
                      <img src="@/assets/landing/turbo-sms-icon.svg" alt="turbo-sms" />
                    </div>
                  </div>
                </div>
              </div>

           </div>
          </div>
        </div>
      </div>
    </section>

    <section class="simple">
      <div class="container">
        <div class="for-title">
          <div class="ns-title">
            <div class="gr">{{ $t("landing.alone_hard") }}</div>
            <div class="fr-ln">
              <div>
                <div v-html="$t('landing.with_ns_easier')"></div>
                <div class="plain">
                  <img src="@/assets/landing/simple-plain.png" alt="plain" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="s-row">
          <div class="row">
            <div class="col-12 col-md-6 d-none d-md-block">
              <div class="s-block sbl-1">
                <div class="list">
                  <p>{{ $t("landing.sending_orders") }}</p>
                  <p>{{ $t("landing.acceptance_items") }}</p>
                  <p>{{ $t("landing.сomplete_set_and_packaging") }}</p>
                  <p>{{ $t("landing.сompilation_of_documentation") }}</p>
                  <p>{{ $t("landing.quality_control") }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="s-block sbl-2">
                <div class="list">
                  <div class="min-title">{{ $t("landing.sending_orders") }}</div>
                  <div class="min-title">{{ $t("landing.acceptance_items") }}</div>
                  <div class="min-title">{{ $t("landing.сomplete_set_and_packaging") }}</div>
                  <div class="min-title">{{ $t("landing.сompilation_of_documentation") }}</div>
                  <div class="min-title">{{ $t("landing.quality_control") }}</div>
                </div>
                <div class="ready">
                  <img src="@/assets/landing/ready.png" alt="ready" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="s-bottom">
          <div class="row align-items-center">
            <div class="col-12 col-md col-lg-auto col-xl-7">
              <div class="min-title" v-html="$t('landing.why_waste_time')"></div>
            </div>
            <div class="col-12 col-md-auto">
              <div class="logo">
                <img src="@/assets/landing/logo.png" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="starts">
      <div class="container">
        <div class="ns-title" v-html="$t('landing.start_working')"></div>
        <div class="s-row">
          <div class="circle">
            <img src="@/assets/landing/circ-arrows.png" alt="circle" />
          </div>
          <div class="logo">
            <img src="@/assets/landing/logo2.png" alt="logo" />
          </div>
          <div class="row rw-1 justify-content-center">
            <div class="col-4 col-lg-auto">
              <div class="s-block sb-1">
                <div class="icon">
                  <img src="@/assets/landing/s1.png" alt="icon" />
                </div>
                <p v-html="$t('landing.set_apply')"></p>
              </div>
            </div>
            <div class="col-4 col-lg-auto">
              <div class="s-block sb-2">
                <div class="icon">
                  <img src="@/assets/landing/s2.png" alt="icon" />
                </div>
                <p>{{ $t("landing.choose_service") }}</p>
              </div>
            </div>
            <div class="col-4 col-lg-auto">
              <div class="s-block sb-3">
                <div class="icon">
                  <img src="@/assets/landing/s3.png" alt="icon" />
                </div>
                <p v-html="$t('landing.sing_contract')"></p>
              </div>
            </div>
          </div>
          <div class="s-block sb-4">
            <div class="icon">
              <div class="allready">
                <img src="@/assets/landing/allready.png" alt="allready" />
              </div>
              <img src="@/assets/landing/s4.png" alt="icon" />
            </div>
            <p>{{ $t("landing.ship_our_goods") }}</p>
          </div>
        </div>
      </div>
    </section>

    <footer id="zayavka" name="footer" class="footer">
      <div class="line tp">
        <img src="@/assets/landing/black-line.png" alt="line" />
      </div>
      <div class="container">
        <div class="plain">
          <img src="@/assets/landing/footer-plain.png" alt="plain" />
        </div>
        <div class="row align-items-center align-items-lg-end">
          <div class="col-12 col-lg-4 order-3 order-lg-1">
            <div class="f-1">
              <div class="logo">
                <a><img src="@/assets/landing/logo3.png" alt="logo"/></a>
              </div>
              <div class="f-block">
                <p class="tit">{{ $t("landing.contant_info") }}</p>
                <div class="email fbs">
                  <a href="mailto:newsend.ua@gmail.com">newsend.ua@gmail.com</a>
                </div>
                <div class="phone fbs">
                  <a href="tel:+380681803018">+38 068-18-030-18</a>
                </div>
                <div class="fbs">
                  <a href="https://my.newsend.ua/pdf/politics.pdf">{{
                    $t("landing.contract_offers")
                  }}</a>
                </div>
                <div class="address fbs">
                  {{ $t("landing.address") }}
                </div>
                <div class="fbs">
                  <a href="https://www.facebook.com/Newsend.com.ua" class="text-decoration-none" target="_blank">
                    <div class="d-flex align-center">
                      <VIcon color="success" class="mr-1">mdi-facebook</VIcon>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-lg-4 order-1 order-lg-2">
            <div class="f-2">
              <div class="for-title">
                <div class="ns-title" v-html="$t('landing.scale_business')"></div>
                <div class="text">
                  <p class="ff-1">{{ $t("landing.we_help") }}</p>
                  <p class="ff-2">{{ $t("landing.drop_contacts") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 order-2">
            <div class="f-3">
              <div class="forma fr-circle white">
                <div class="min-title" v-html="$t('landing.get_consultation')"></div>
                <form>
                  <div class="inputblock">
                    <input
                      :placeholder="$t('landing.form.name_surname')"
                      id="focusinput"
                      name="name"
                      type="text"
                      required
                      v-model="userData.name"
                      @keypress="validationForName($event)"
                      @paste="validationForName($event)"
                    />
                  </div>
                  <div class="inputblock">
                    <div class="input-box">
                      <span class="prefix">+38</span>
                      <input
                        :placeholder="$t('landing.form.phone')"
                        name="phone"
                        type="text"
                        required
                        maxlength="13"
                        prefix
                        v-model="userData.phone"
                        @keypress="validationForPhoneNumber($event)"
                        @paste="validationForPhoneNumber($event)"
                      />
                    </div>
                  </div>
                  <input type="hidden" name="tarif" id="tarif" value="" />
                  <div class="order">
                    <button
                      class="send"
                      type="button"
                      @click="createJoinRequest()"
                      :disabled="loadingRequest"
                    >
                      {{ $t("landing.btn.send") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="dev">
          <a href="https://bmax.com.ua/" class="text-decoration-none" target="_blank">
            <div class="d-flex align-center">
              <img src="@/assets/landing/bmax-logo.svg" alt="logo"/>
                <div class="text">
                  {{$t("landing.developed_by")}} Bmax
                </div>
              </div>
            </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import notifications from "@/mixins/notifications";
import joinRequestService from "@/services/request/joinRequest/joinRequestService";
import phoneMask from "@/validators/phoneMask";
import { validationMixin } from "vuelidate";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import authService from "@/services/request/auth/authService";
import {validationForName, validationForPhoneNumber} from "@/mixins/helpers";

export default {
  data: () => ({
    activeTarrif: null,
    paymentPlansList: [],
    userData: {
      name: "",
      phone: ""
    },
    loadingRequest: false,
    authenticated: false,
    planItem: "",
  }),
  validations: {
    userData: {
      name: { required },
      phone: { phoneMask, required }
    }
  },
  mixins: [notifications],
  computed: {
    ...mapGetters(["languages", "currentLanguage"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.userData.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.userData.name.required &&
        this.setErrorNotification(this.$t("form.errors.NameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.userData.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.userData.phone.required &&
        this.setErrorNotification(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.userData.phone.phoneMask &&
        this.setErrorNotification(this.$t("form.errors.PhoneNumberMustBeLike"));

      return errors;
    }
  },
  mounted() {
    if (authService.getToken()) {
      this.authenticated = true
    }
    // this.getPlansList();
    this.getPlanDefault();
  },
  methods: {
    validationForPhoneNumber,
    validationForName,
    getTarrifParagraf(tarrif){
      if(tarrif.description){
       return  tarrif.description.split('\n')
      }else{
        return []
      }
    },
    onClickDock() {
      const routeData = this.$router.resolve({
        name: "landing-documents",
        params: {
          lang: this.currentLanguage.key,
          uuid: 'all'
        }
      });
      window.open(routeData.href, "_blank");
    },
    async createJoinRequest() {
      try {
        this.$v.$touch();
        this.nameErrors;
        this.phoneErrors;
        if (this.$v.$invalid) return;
        this.loadingRequest = true;
        const formData = new FormData();
        formData.append("name", this.userData.name);
        formData.append("phone", this.userData.phone);

        await joinRequestService.addJoinRequest(formData);
        this.userData = {
          name: "",
          phone: ""
        };
        this.loadingRequest = false;
        this.setSuccessNotification(this.$t("landing.notifications.successfully_sended"));
      } catch (e) {
        this.loadingRequest = false;
        console.log(e)
      }
    },
    switchLanguage(item) {
      if (this.currentLanguage.translationKey !== item.translationKey) {
        this.$setLanguage(item.translationKey);
      }
    },
    // async getPlansList() {
    //   try {
    //     this.loadingList = true;
    //     const plansItem = await paymentPlansService.usersPlansList();
    //     if (plansItem.length) {
    //       this.paymentPlansList = plansItem.reverse().slice(0, 8);
    //       this.activeTarrif = this.paymentPlansList.find(plan => plan.is_default === "1").uuid;
    //     }
    //     this.loadingList = false;
    //   } catch (e) {
    //     this.loadingList = false;
    //     console.log(e)
    //   }
    // },
    async getPlanDefault(){
      try{
        this.planItem = await paymentPlansService.usersPlansDefault();
      }
      catch (e) {
        console.log(e)
      }
    },
    scrollTo(name) {
      const element = document.getElementsByName(name);
      if (element.length) {
        element[0].scrollIntoView({ behavior: "smooth" });
      }
    }
  },
  wathc: {
    activeTarrif(e) {

    }
  }
};
</script>
<style lang="scss">
html {
  overflow-y: scroll !important;
}
</style>
<style lang="scss" scoped>
@import url("./scss/anim.scss");
@import url("./scss/bootstrap.scss");
@import url("./scss/fonts.scss");
@import url("./scss/scrollbar.scss");
@import url("./scss/style.scss");
body {
  scroll-behavior: smooth;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  line-height: 1.3;
  color: #000000;
  margin: 0;
  background: #fff;
  position: relative;
  font-weight: 400;
}
@media (min-width: 768px) and (max-width: 1199px) {
  body {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 16px;
  }
}
body .wrapper {
  overflow: hidden;
}
body .container {
  position: relative;
}
body p.min {
  font-size: 16px;
}
body p.big {
  font-size: 20px;
}
@media (max-width: 767px) {
  body p.big {
    font-size: 16px;
  }
  body p.min {
    font-size: 14px;
  }
}
body .min-title {
  font-size: 24px;
  line-height: 1;
}
body .min-title.big {
  font-size: 30px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  body .min-title {
    font-size: 20px;
  }
  body .min-title.big {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  body .min-title {
    font-size: 19px;
  }
  body .min-title.big {
    font-size: 19px;
  }
}
body .ns-title {
  font-size: 45px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 768px) and (max-width: 1199px) {
  body .ns-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  body .ns-title {
    font-size: 24px;
  }
}
body p,
body .min-title,
body .ns-title,
body .order {
  position: relative;
  z-index: 2;
  margin: 0;
}
body .order a {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}
body .order a,
body .order button {
  border-radius: 50px;
  padding: 5px 10px;
  min-height: 50px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  background-color: #4fae3b;
  color: #ffffff;
  font-weight: 700;
}
body .order a:hover,
body .order button:hover {
  background-color: #2c9017;
}
body .register button {
  font-size: 11px;
}
body img {
  width: auto;
  height: auto;
}
body a,
body input,
body button {
  transition: all 0.2s ease-in-out;
  outline: none;
}
body form ::-webkit-input-placeholder {
  color: #cccccc;
}
body form ::-moz-placeholder {
  color: #cccccc;
}
body form :-moz-placeholder {
  color: #cccccc;
}
body form :-ms-input-placeholder {
  color: #cccccc;
}
body form label.error {
  display: none !important;
}
body form input,
body form select {
  border: 1px solid transparent;
  width: 100%;
  border-radius: 36px;
  height: 36px;
  line-height: 50px;
  padding: 0 15px;
  text-align: center;
  text-align-last: center;
  color: #000;
  background-color: #e6e7e8;
}
body form input:focus,
body form select:focus {
  border-color: #4fae3b;
}
body form input.error,
body form select.error {
  border-color: red;
  box-shadow: 0 0 4px #cc0000;
}
body form input {
  height: 34px;
  line-height: 34px;
}
body form select {
  line-height: 1;
}
body form select option {
  font-size: 18px;
}
body form textarea {
  padding: 10px 16px;
  resize: none;
  line-height: 17px;
}
body form .order button {
  outline: none;
}
body .alignnone {
  margin: 5px 20px 20px 0;
}
body .aligncenter,
body div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}
body .alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
body .alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
body a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
body a img.alignnone {
  margin: 5px 20px 20px 0;
}
body a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
body a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
body .wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  padding: 5px 3px 10px;
  text-align: center;
}
body .wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}
body .wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}
body .wp-caption.alignright {
  margin: 5px 0 20px 20px;
}
body .wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}
body .wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}
body .screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
body .screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
.manage {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  background: #ffffff;
  height: 45px;
  padding: 0 15px;
  z-index: 10002;
  justify-content: space-between;
  align-items: center;
}
.manage .humb {
  width: 35px;
  height: 30px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.manage .humb div {
  width: 100%;
  height: 3px;
  background: #8ac657;
}
.manage .logo {
  width: 115px;
}
.left-menu {
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  height: 100%;
  width: 290px;
  background: rgba(255, 255, 255, 0.93);
  z-index: 10003;
  overflow-y: scroll;
  transition: 0.3s;
}
.left-menu.active {
  transform: none;
}
.left-menu .links {
  padding-left: 20px;
}
.left-menu .links ul {
  padding: 0;
  list-style-type: none;
}
.left-menu .links ul li {
  margin: 5px 0;
}
.left-menu .links ul li a {
  color: #7b1eb6;
  font-size: 18px;
}
.left-menu .close-menu {
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 34px;
  font-weight: bold;
  line-height: 10px;
  height: 10px;
  color: #7b1eb6;
}
.languages-widget-block .wpml-ls-legacy-dropdown {
  width: auto;
  max-width: auto;
}
.languages-widget-block .wpml-ls-legacy-dropdown > ul > li > a {
  border: none;
  background: none !important;
  text-decoration: none;
}
.languages-widget-block .wpml-ls-legacy-dropdown > ul > li > a:after {
  right: 11px;
  top: 7px;
  border: 3px solid transparent;
  border-top: 5px solid;
  display: none;
}
.languages-widget-block .wpml-ls-legacy-list-horizontal {
  padding: 0;
}
.languages-widget-block ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.languages-widget-block ul li:not(:last-of-type) {
  border-right: 3px solid #4fae3b;
}
.languages-widget-block ul li.wpml-ls-current-language a {
  font-weight: 700;
  color: #4fae3b !important;
}
.languages-widget-block ul li a {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 0 7px;
  font-weight: 400;
  line-height: 13px;
  display: block;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .languages-widget-block ul li a {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .languages-widget-block ul li a {
    font-size: 16px;
  }
}
.pagination {
  text-align: center;
  flex-wrap: wrap;
  margin: 60px 0 0 0;
}
.pagination h2 {
  flex-basis: 100%;
  display: none;
}
.pagination .nav-links {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  align-items: center;
}
.pagination .nav-links span {
  border-radius: 3px;
  background-color: #8ac657;
  display: inline-block;
  color: #ffffff;
  padding: 5px;
  font-size: 18px;
  padding: 5px 10px;
  margin: 0 5px;
}
.pagination .nav-links a {
  padding: 5px 10px;
  margin: 0 5px;
  color: #000000;
  border-radius: 3px;
}
.pagination .nav-links a.next,
.pagination .nav-links a.prev {
  background-color: #8ac657;
  display: inline-block;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 18px;
  text-decoration: none;
}
.pagination .nav-links a:hover:hover {
  background-color: #62a02d;
}
.menu-wrapper .menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.line {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.line.bt {
  top: calc(100% - 2px);
  transform: scale(1, -1);
}
.line.tp {
  bottom: calc(100% - 2px);
}
@media (max-width: 767px) {
  .line img {
    height: 40px;
  }
}
.plain {
  position: absolute;
}
.fr-circle {
  width: 346px;
  height: 343px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.fr-circle .min-title {
  margin: 0 0 22px 0;
  font-weight: 700;
}
.fr-circle .inputblock {
  margin: 0 0 10px 0;
}
.fr-circle .order {
  margin: 20px 0 0 0;
}
.fr-circle .order button {
  width: 170px;
}
.fr-circle form {
  max-width: 225px;
  margin: 0 auto;
}
.fr-circle.white {
  background-color: #fff;
  background-image: url(./../../assets/landing/black-circle.png);
}
.fr-circle.white .min-title {
  color: #4fae3b;
}
.fr-circle.black {
  background-color: #000;
  background-image: url(./../../assets/landing/white-circle.png);
}
.fr-circle.black .min-title {
  color: #fff;
}
.video {
  position: relative;
  z-index: 10;
}
.video:before,
.video:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 35%;
  z-index: 100;
}
.video:before {
  top: 0;
}
.video:after {
  bottom: 0;
}
.header {
  padding: 25px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  background: none;
}
.header .logo img {
  width: 180px;
  height: auto;
}
.header .phone a {
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}
.header .phone a:hover {
  color: #4fae3b;
}
.header .order a {
  text-transform: none;
  font-size: 12px;
  min-height: 0;
}
.header .menu-wrapper .menu li:not(:last-of-type) {
  margin-right: 10px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header .languages-widget-block ul li a {
    line-height: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header .phone {
    margin: 0 0 10px 0;
  }
}
@media (max-width: 767px) {
  .header {
    padding: 15px 0;
  }
  .header .container {
    max-width: 420px;
  }
  .header .logo img {
    width: 120px;
  }
  .header .languages-widget-block {
    margin: 0 0 15px 0;
  }
  .header .languages-widget-block ul li a {
    line-height: 10px;
  }
  .header .order {
    margin: 15px 0 0 0;
  }
  .header .order a {
    width: 130px;
  }
}
.footer {
  background-color: #000;
  padding: 20px 0 30px 0;
  position: relative;
}
.footer .plain {
  top: 11px;
  left: -30px;
}
.footer .f-1 {
  font-size: 14px;
}
.footer .f-1 p,
.footer .f-1 div {
  font-size: 14px;
}
.footer .f-1 .f-block {
  color: #4fae3b;
  margin: 30px 0 0 0;
}
.footer .f-1 .f-block a {
  color: #4fae3b;
}
.footer .f-1 .f-block .tit {
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 15px 0;
}
.footer .f-1 .f-block .fbs:not(:last-of-type) {
  margin-bottom: 10px;
}
footer .dev{
  margin-top: 40px;
}
footer  .dev img{
  width: 40px;
}
footer .dev .text {
  margin-left: 8px;
  font-size: 12px;
  color: #a6a6a6;
}
.footer .f-2 {
  color: #fff;
  text-align: right;
  margin: 0 50px 0 -80px;
}
.footer .f-2 .ns-title {
  font-size: 48px;
  margin: 0 0 65px 0;
}
.footer .f-2 .text p:not(:last-of-type) {
  margin-bottom: 25px;
}
.footer .f-2 .text .ff-2 {
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .footer .plain {
    display: none;
  }
  .footer .f-2 .ns-title {
    font-size: 38px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer .f-2 {
    margin: 0 0px 0 -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer .f-1 {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer .f-2 {
    margin: 0 -20px 0 0;
    text-align: left;
  }
  .footer .f-2 .ns-title {
    margin: 0 0 25px 0;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 20px 0 40px 0;
  }
  .footer .plain {
    display: none;
  }
  .footer .f-2 {
    margin: 0 -20px 0 0;
    text-align: left;
  }
  .footer .f-2 .ns-title {
    font-size: 30px;
    margin: 0 0 25px 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .footer .f-1 {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer .f-1 .f-block {
    max-width: 260px;
  }
}
@media (max-width: 575px) {
  .footer {
    text-align: center;
  }
  .footer .container {
    max-width: 320px;
  }
  .footer .f-2 {
    text-align: center;
    margin: 0 0 20px 0;
  }
  .footer .f-3 {
    margin: 0 -20px 25px -30px;
  }
}
.error404 {
  text-align: center;
}
.error404 .wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
}
.error404 .wrapper section {
  flex-grow: 1;
}
.error404 .nf-inner .nf-number {
  font-size: 190px;
  color: #88c559;
  line-height: 1;
}
.error404 .nf-inner .ns-title {
  margin: 0px 0 15px 0;
  color: #5a5a5a;
}
.error404 .nf-inner p {
  font-size: 19px;
}
.error404 .nf-inner p a {
  text-decoration: underline;
  color: #7b21b4;
}
.error404 .nf-inner p a:hover {
  text-decoration: none;
}
.search .search-inner {
  padding: 80px 0 50px 0;
}
.searching {
  margin: 0 0 39px 0;
}
.searching form {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.searching form input {
  height: 61px;
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  font-size: 24px;
  padding: 0 26px;
  text-align: left;
}
.searching form button {
  width: 60px;
  min-height: 61px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  min-width: 0;
  margin: 0 0 0 5px;
}
.entry-inner .entry-image {
  height: 218px;
  overflow: hidden;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  display: block;
}
.entry-inner .entry-image img {
  max-width: 100%;
}
.entry-inner .entry-content {
  padding: 32px 22px 30px 22px;
}
.entry-inner .entry-content .excerpt {
  margin: 37px 0 55px 0;
  text-align: justify;
}
.blog .blog-items {
  padding: 50px 0 50px 0;
}
.single .for-single {
  padding: 82px 0 100px 0;
  background: #f8f8f8;
}
.single .for-single .single-inner {
  padding: 48px 0 64px 0;
}
.single .for-single .single-inner .single-text {
  border: 2px dotted #d2d2d2;
  padding: 15px 20px;
  background: #ffffff;
}
.single .for-single .single-inner .single-text p:not(:last-of-type) {
  margin: 0 0 15px 0;
}
.category .category-items {
  padding: 50px 0 50px 0;
}
.simple-page {
  padding: 150px 0 100px 0;
}
.simple-page p {
  margin: 0 0 20px 0;
}
.auth .header {
  background: #000;
  position: relative;
  padding: 25px 0 0 0;
}
.auth .forma {
  max-width: 330px;
  margin: auto;
  position: relative;
  z-index: 2;
}
.auth .forma .inputblock {
  position: relative;
}
.auth .forma .inputblock:not(:last-of-type) {
  margin-bottom: 7px;
}
.auth .forma .inputblock.bigMr {
  margin: 0 0 32px 0;
}
.auth .forma .inputblock input {
  height: 54px;
  line-height: 54px;
  position: relative;
}
.auth .forma .inputblock .succ-icon {
  position: absolute;
  left: calc(100% + 10px);
  bottom: 5px;
}
.auth .forma .order button {
  width: 100%;
}
@media (max-width: 767px) {
  .auth .forma .inputblock {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
  }
  .auth .forma .inputblock input {
    height: 45px;
    line-height: 45px;
  }
  .auth .forma .inputblock .succ-icon {
    position: initial;
    margin: 0 0 0 10px;
  }
  .auth .forma .inputblock .succ-icon img {
    width: 22px;
  }
  .auth .forma .inputblock.bigMr {
    margin: 0 0 20px 0;
  }
}
.tech .footer .plain {
  top: 0px;
  left: -60px;
  transform: rotate(11deg);
}
.tech .footer .plain img {
  max-width: 740px;
}
.tech .footer .f-1 {
  display: block;
}
.tech .footer .f-2 .ns-title {
  text-transform: none;
}
@media (max-width: 767px) {
  .tech .footer {
    text-align: center;
  }
  .tech .footer .f-1 .f-block {
    margin: 30px auto 0;
  }
  .tech .footer .f-2 {
    text-align: center;
    margin: 0 0 40px 0;
  }
}
@media (max-width: 575px) {
  .tech .footer .plain {
    display: block;
    transform: none;
    top: 17px;
    left: -70px;
  }
  .tech .footer .plain img {
    max-width: 410px;
  }
}
.thanks-page {
  padding: 170px 0 150px 0;
  margin: 0 0 60px 0;
  text-align: center;
  //   background: url(./../../assets/landing/thanks-bg.jpg) no-repeat center bottom;
}
.thanks-page div,
.thanks-page p {
  color: #fff;
}
.thanks-page .ns-title {
  max-width: 466px;
  height: 466px;
  margin: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  //   background: url(./../../assets/landing/th-circle.png) no-repeat center center / contain;
}
.thanks-page .ns-title span {
  font-weight: 440;
}
.thanks-page .arrow {
  margin: -70px 0 0 0;
  position: relative;
  z-index: 5;
}
.thanks-page p {
  font-weight: 700;
}
@media (max-width: 767px) {
  .thanks-page {
    padding: 114px 0 80px 0;
    margin: 0 0 35px 0;
    background-size: cover;
  }
}
.enter-page {
  padding: 150px 0 150px 0;
  background: #fff;
  text-align: center;
}
.enter-page .ns-title {
  color: #4fae3b;
  margin: 0 0 95px 0;
}
.enter-page .arrow {
  position: absolute;
  left: -90px;
  top: 70px;
}
.enter-page .forma .regs {
  margin: 16px 0 0 0;
  font-weight: 700;
}
.enter-page .forma .regs a {
  text-transform: uppercase;
  text-decoration: underline;
  color: #000;
}
.enter-page .forma .regs a:hover {
  text-decoration: none;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .enter-page .arrow {
    left: -130px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .enter-page .arrow {
    left: -190px;
  }
}
@media (max-width: 767px) {
  .enter-page {
    padding: 70px 0 80px 0;
  }
  .enter-page .ns-title {
    margin: 0 0 25px 0;
  }
  .enter-page .arrow {
    position: initial;
  }
  .enter-page .arrow img {
    width: 290px;
    margin: -30px 0 0 0;
  }
}
.reg-page {
  padding: 150px 0 150px 0;
}
.reg-page .arrow {
  position: absolute;
  left: -90px;
  top: 20px;
}
.reg-page .min-title {
  color: #4fae3b;
  margin: 0 0 36px 0;
  text-align: center;
  font-weight: 700;
}
@media (max-width: 767px) {
  .reg-page {
    padding: 75px 0 80px 0;
  }
  .reg-page .min-title {
    margin: 0 0 25px 0;
  }
  .reg-page .arrow {
    position: initial;
    text-align: center;
    margin: 20px 0 20px 0;
  }
  .reg-page .arrow img {
    width: 290px;
  }
}
.front-page .main {
  padding: 240px 0 65px 0;
  text-align: center;
  background: url(./../../assets/landing/main-bg.jpg) no-repeat center top / cover;
}
.front-page .main .for-title .ns-title {
  margin: 0 0 25px 0;
  color: #4fae3b;
}
.front-page .main .for-title .ns-title div {
  font-weight: 400;
}
.front-page .main .for-title .min-title {
  color: #fff;
}
.front-page .main .order {
  max-width: 343px;
  margin: 45px auto 0;
  border: 3px solid #ffffff;
  padding: 6px;
  position: relative;
  border-radius: 50px;
}
.front-page .main .order .plain {
  right: calc(100% - 60px);
  bottom: 27px;
}
.front-page .main .order a {
  width: 100%;
  min-height: 54px;
}
.front-page .main .m-bottom .m-block {
  margin: 85px 0 0 0;
}
.front-page .main .m-bottom .m-block .icon {
  width: 102px;
  height: 102px;
  margin: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.front-page .main .m-bottom .m-block .icon img {
  max-width: 100%;
  max-height: 100%;
}
.front-page .main .m-bottom .m-block p {
  font-weight: 700;
  margin: 45px 0 0 0;
  color: #fff;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .front-page .main .order .plain img {
    width: 330px;
  }
}
@media (max-width: 767px) {
  .front-page .main {
    padding: 255px 0 40px 0;
  }
  .front-page .main .order {
    border-width: 2px;
    padding: 3px;
    max-width: 290px;
  }
  .front-page .main .order a {
    min-height: 45px;
  }
  .front-page .main .order .plain {
    right: calc(100% - 160px);
    bottom: 67px;
  }
  .front-page .main .order .plain img {
    width: 260px;
  }
  .front-page .main .m-bottom .m-block {
    margin: 45px 0 0 0;
  }
  .front-page .main .m-bottom .m-block .icon {
    width: 70px;
    height: 70px;
  }
  .front-page .main .m-bottom .m-block p {
    margin: 11px 0 0 0;
  }
}
.front-page .what {
  padding: 70px 0 30px 0;
  background: #000;
  position: relative;
}
.front-page .what p,
.front-page .what div {
  color: #fff;
}
.front-page .what .ns-title {
  font-weight: 400;
  margin: 0 0 60px 0;
}
.front-page .what .ns-title .fr-ln {
  font-weight: 700;
  color: #4fae3b;
  position: relative;
  display: inline-block;
}
.front-page .what .ns-title .fr-ln .plain {
  right: 0;
  top: 77px;
}
.front-page .what .min-title {
  text-transform: uppercase;
}
.front-page .what .min-title p {
  text-transform: none;
  display: inline;
}
.front-page .what .min-title span {
  font-weight: 700;
}
.front-page .what .video {
  border: 5px solid #fff;
  background: #fff;
  border-radius: 5px;
  max-width: 660px;
  margin: auto;
}
.front-page .what .video video {
  z-index: 1000;
  cursor: pointer;
  outline: none;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .front-page .what .ns-title .fr-ln .plain {
    top: 37px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .front-page .what .ns-title {
    margin: 0 0 30px 0;
  }
  .front-page .what .ns-title .fr-ln .plain {
    top: 57px;
  }
  .front-page .what .ns-title .fr-ln .plain img {
    width: 760px;
  }
}
@media (max-width: 767px) {
  .front-page .what {
    padding: 40px 0 0px 0;
  }
  .front-page .what .ns-title {
    margin: 0 0 15px 0;
  }
  .front-page .what .ns-title .fr-ln .plain {
    right: -90px;
    top: -23px;
  }
  .front-page .what .ns-title .fr-ln .plain img {
    max-width: 576px;
  }
  .front-page .what .w-right {
    text-align: right;
    padding: 15px 0 0 25px;
  }
  .front-page .what .w-right .min-title p {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .front-page .what .container {
    max-width: 320px;
  }
  .front-page .what .ns-title {
    margin: 0 0 15px 0;
  }
  .front-page .what .ns-title .fr-ln .plain {
    right: -80px;
    top: -23px;
  }
  .front-page .what .ns-title .fr-ln .plain img {
    max-width: 396px;
  }
}
.front-page .produce {
  padding: 150px 0 100px 0;
}
.front-page .produce .ns-title {
  color: #4fae3b;
}
.front-page .produce .ns-title div {
  font-weight: 400;
}
.front-page .produce .ns-title div .logo {
  display: inline-block;
  margin: 0 0 0px 30px;
}
.front-page .produce .ns-title div .logo img {
  margin-top: -10px;
  width: 145px;
}
.front-page .produce .p-row {
  margin: -100px 0 0 0;
}
.front-page .produce .p-block.pp-1 {
  margin: 310px 0 0 0;
}
.front-page .produce .p-block.pp-2 {
  margin: 135px 0 0 0;
}
.front-page .produce .p-block .order .icon {
  margin: 0 0 -75px 0;
  text-align: center;
}
.front-page .produce .p-block .order a {
  text-transform: none;
  min-height: 75px;
  color: #fff;
  width: 100%;
  pointer-events: none;
}
.front-page .produce .p-block .card {
  padding: 28px 0 0 0;
  border: none;
  box-shadow: none;
}
.front-page .produce .p-block .card .list p {
  padding: 0 0 0 21px;
}
.front-page .produce .p-block .card .list p .ins {
  position: absolute;
  left: calc(100% - 80px);
  bottom: -30px;
}
.front-page .produce .p-block .card .list p:before {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  width: 13px;
  height: 13px;
  background-color: #4fae3b;
  border-radius: 50%;
}
.front-page .produce .p-block .card .list p:not(:last-of-type) {
  margin-bottom: 25px;
}
.front-page .produce .p-block .card .list p span {
  font-weight: 600;
}
.front-page .produce .forma {
  position: absolute;
  right: 15px;
  bottom: 0;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .front-page .produce .p-block .card .list p .ins img {
    width: 130px;
  }
  .front-page .produce .forma {
    right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .front-page .produce .p-row {
    margin: 0;
  }
  .front-page .produce .p-block {
    margin: 45px auto 0 !important;
  }
  .front-page .produce .forma {
    position: initial;
    margin: 30px auto 0;
  }
}
@media (max-width: 767px) {
  .front-page .produce {
    padding: 70px 0 40px 0;
  }
  .front-page .produce .p-row {
    margin: 0;
  }
  .front-page .produce .p-block {
    margin: 30px auto 0 !important;
  }
  .front-page .produce .p-block .card .list p .ins {
    bottom: -20px;
  }
  .front-page .produce .p-block .card .list p .ins img {
    width: 100px;
  }
  .front-page .produce .p-block .order {
    text-align: center;
  }
  .front-page .produce .p-block .order .icon {
    margin: 0 0 -40px 0;
  }
  .front-page .produce .p-block .order .icon img {
    width: 120px;
  }
  .front-page .produce .p-block .order a {
    min-height: 55px;
    max-width: 300px;
    pointer-events: all;
    position: relative;
  }
  .front-page .produce .p-block .order a:before {
    position: absolute;
    content: "";
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 15px;
    border-width: 2px;
    border-bottom-style: solid;
    border-right-style: solid;
    transform: rotate(-45deg);
    border-color: #fff;
    transition: 0.3s;
  }
  .front-page .produce .p-block .order a:not(.collapsed):before {
    transform: rotate(45deg);
  }
  .front-page .produce .forma {
    margin: 30px -30px;
    position: initial;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .front-page .produce .forma {
    margin: 30px auto 0;
  }
}
@media (max-width: 575px) {
  .front-page .produce .container {
    max-width: 320px;
  }
}
.front-page .simple {
  padding: 30px 0 140px 0;
  background: #000;
  position: relative;
}
.front-page .simple .container {
  max-width: 840px;
}
.front-page .simple .for-title .ns-title .gr {
  color: #4fae3b;
}
.front-page .simple .for-title .ns-title .fr-ln {
  text-align: right;
}
.front-page .simple .for-title .ns-title .fr-ln > div {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  position: relative;
}
.front-page .simple .for-title .ns-title .fr-ln > div span {
  font-weight: 400;
}
.front-page .simple .for-title .ns-title .fr-ln > div .plain {
  right: calc(100% - 30px);
  top: 20px;
}
.front-page .simple .s-block {
  position: relative;
  margin: 65px 0 0 0;
}
.front-page .simple .s-block .ready {
  top: 100%;
  right: -60px;
  position: absolute;
}
.front-page .simple .s-block .list div,
.front-page .simple .s-block .list p {
  position: relative;
  padding: 0 0 0 50px;
}
.front-page .simple .s-block .list div:before,
.front-page .simple .s-block .list p:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 23px;
  height: 23px;
  border: 3px solid #4fae3b;
  border-radius: 50%;
}
.front-page .simple .s-block.sbl-1 {
  color: #4fae3b;
}
.front-page .simple .s-block.sbl-1 .list p:not(:last-of-type) {
  margin-bottom: 21px;
}
.front-page .simple .s-block.sbl-2 {
  color: #4fae3b;
}
.front-page .simple .s-block.sbl-2 .list div {
  color: #fff;
  font-weight: 700;
}
.front-page .simple .s-block.sbl-2 .list div:before {
  border-color: #fff;
}
.front-page .simple .s-block.sbl-2 .list div:after {
  width: 39px;
  height: 30px;
  position: absolute;
  content: "";
  left: -3px;
  top: -13px;
  background: url(./../../assets/landing/oks.png) no-repeat center / contain;
}
.front-page .simple .s-block.sbl-2 .list div:not(:last-of-type) {
  margin-bottom: 19px;
}
.front-page .simple .s-bottom {
  margin: 62px 0 0 0;
}
.front-page .simple .s-bottom .min-title {
  color: #fff;
  text-transform: uppercase;
}
.front-page .simple .s-bottom .min-title span {
  font-weight: 700;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .front-page .simple .container {
    max-width: 700px;
  }
}
@media (max-width: 767px) {
  .front-page .simple {
    padding: 0px 0 35px 0;
  }
  .front-page .simple .for-title {
    max-width: 290px;
    margin: auto;
  }
  .front-page .simple .for-title .ns-title .fr-ln > div .plain {
    right: calc(100% - 170px);
    top: 90px;
    transform: rotate(-45deg);
  }
  .front-page .simple .for-title .ns-title .fr-ln > div .plain img {
    width: 400px;
  }
  .front-page .simple .s-block {
    margin: 45px auto 0;
    max-width: 230px;
  }
  .front-page .simple .s-block .ready {
    right: -30px;
  }
  .front-page .simple .s-block .ready img {
    width: 100px;
  }
  .front-page .simple .s-bottom {
    text-align: right;
  }
  .front-page .simple .s-bottom .min-title {
    color: #4fae3b;
    width: 60%;
    margin-left: auto;
  }
  .front-page .simple .s-bottom .logo {
    margin: 20px 0 0 0;
    text-align: center;
  }
  .front-page .simple .s-bottom .logo img {
    width: 140px;
  }
}
@media (max-width: 575px) {
  .front-page .simple .container {
    max-width: 320px;
  }
  .front-page .simple .s-bottom .min-title {
    width: 235px;
  }
}


.front-page .tariffs {
  padding-bottom: 40px ;
  position: relative;
}
.front-page .tariffs .container {
  max-width: 1300px;
}
.front-page .tariffs .plane-tariff {
  position: absolute;
  bottom: -70px;
  left: -70px;
  z-index: 1;
}
.front-page .tariffs .t-block {
  max-width: 1280px;
  height: 786px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 3px solid #4FAE3B;
  background: #FFF;
  padding-top: 64px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.front-page .tariffs .t-block .t-block-container {
  display: flex;
  width: 856px;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  z-index: 2;
}
.front-page .tariffs .t-block .t-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.front-page .tariffs .t-block .t-header .t-header-title {
  color: #4FAE3B;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 12px 20px;
  align-self: stretch;
}
.front-page .tariffs .t-block .t-header .t-header-subtitle {
  font-size: 20px;
  padding: 7px 20px;
  text-align: center;
}
.front-page .tariffs .t-block .t-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 22px;
}

.front-page .tariffs .t-block .t-content-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #F3F3F3;
  background: #FDFDFD;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
  padding: 20px;
  gap: 32px;
  width: 272px;
}
.front-page .tariffs .t-block .t-button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 856px;
}
.front-page .tariffs .t-block .t-button .block-button {
  width: 280px;
  border-radius: 30px;
  border: 2px solid #4FAE3B;
  background: #FFF;
  padding: 2px;
}
.front-page .tariffs .t-block .t-button .block-button .btn-text {
  display: flex;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: var(--g, #4FAE3B);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  color: #FFFFFF;
}
.front-page .tariffs .t-block .t-content-block .block-title {
  color: var(--d, #0D0D0D);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  align-self: stretch;
}
.front-page .tariffs .t-block .t-content-block .block-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.front-page .tariffs .t-block .t-content-block .block-list .list-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.front-page .tariffs .t-block .t-content-block .block-list .list-text .divider-top {
  margin-bottom: 20px;
  width: 240px;
  height: 1px;
  background: rgba(0, 0, 0, 0.10);
}
.front-page .tariffs .t-block .t-content-block .block-list .list-text .divider-bottom {
  margin-top: 20px;
  width: 240px;
  height: 1px;
  background: rgba(0, 0, 0, 0.10);
}
.front-page .tariffs .t-block .t-content-block .block-list .list-text .text-header {
  color: var(--g, #4FAE3B);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.front-page .tariffs .t-block .t-content-block .block-list .list-text .text-content {
  color: #0D0D0D;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1200px) {
  .front-page .tariffs .plane-tariff  {
    margin: 0;
    left: 850px;
    bottom: 480px;
  }
  .front-page .tariffs .plane-tariff .tariff-img {
    transform: scaleX(-1);
    max-height: 400px;
    max-width: 200px;
  }
}
@media (max-width: 1050px) {
  .front-page .tariffs .plane-tariff  {
    margin: 0;
    left: 550px;
    bottom: 1060px;
  }
  .front-page .tariffs .t-block {
    min-height: 1480px;
  }
  .front-page .tariffs .t-block .t-block-container {
    align-items: center;
  }
  .front-page .tariffs .t-block .t-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 22px;
  }
}
@media (max-width: 850px) {
  .front-page .tariffs .plane-tariff  {
    left: 400px;
  }
  .front-page .tariffs .t-block .t-header .t-header-title {
    font-size: 24px;
  }
  .front-page .tariffs .t-block .t-header .t-header-subtitle {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .front-page .tariffs .plane-tariff  {
    left: 350px;
  }
}
@media (max-width: 540px) {
  .front-page .tariffs .plane-tariff  {
    bottom: 1245px;
    left: 390px;
  }
  .front-page .tariffs .plane-tariff .tariff-img {
    max-width: 120px;
  }
  .front-page .tariffs .t-block .t-block-container {
    gap: 32px;
  }
}
@media (max-width: 515px) {
  .front-page .tariffs .plane-tariff  {
    left: 375px;
  }
}
@media (max-width: 500px) {
  .front-page .tariffs .plane-tariff  {
    left: 365px;
  }
}
@media (max-width: 480px) {
  .front-page .tariffs .plane-tariff  {
    left: 340px;
  }
}
@media (max-width: 455px) {
  .front-page .tariffs .plane-tariff  {
    left: 315px;
  }
}
@media (max-width: 430px) {
  .front-page .tariffs .plane-tariff  {
    bottom: 1220px;
    left: 270px;
  }
  .front-page .tariffs .plane-tariff .tariff-img {
    max-width: 140px;
  }
}
@media (max-width: 405px) {
  .front-page .tariffs .plane-tariff  {
    left: 245px;
  }
}
@media (max-width: 380px) {
  .front-page .tariffs .plane-tariff  {
    left: 238px;
  }
}



.front-page .integration {
  padding-bottom: 50px;
}
.front-page .integration .container {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.front-page .integration .container .int-block .int-block-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
}
.front-page .integration .container .int-block .int-block-container .int-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.front-page .integration .container .int-block .int-block-container .int-header .int-header-title {
  color: var(--g, #4FAE3B);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.front-page .integration .container .int-block .int-block-container .int-header .int-header-subtitle {
  color: var(--d, #0D0D0D);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.front-page .integration .container .int-block .int-block-container .int-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.front-page .integration .container .int-block .int-block-container .int-content .int-logo-block {
  display: flex;
  width: 280px;
  height: 130px;
  padding: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #F3F3F3;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
}
.front-page .integration .container .int-block .int-block-container .int-content .int-logo-block .int-header-img {
  display: flex;
  max-width: 200px;
  min-height: 30px;
  max-height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}
.front-page .integration .container .int-block .int-block-container .int-content .int-content-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}
.front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}
.front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section .subsection {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}
.front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section .subsection .subsection-img {
  display: flex;
  height: 130px;
  min-width: 180px;
  padding: 40px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid #F3F3F3;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
}
@media (max-width: 850px) {
  .front-page .integration .container .int-block .int-block-container .int-header .int-header-title {
    font-size: 24px;
  }
  .front-page .integration .container .int-block .int-block-container .int-header .int-header-subtitle {
    font-size: 16px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section {
    flex-direction: column;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section .subsection {
    align-items: flex-start;
  }
}
@media (max-width: 515px) {
  .front-page .integration .container .int-block .int-block-container {
    gap: 32px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content {
    gap: 20px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block {
    gap: 20px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section {
    gap: 20px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section .subsection {
    gap: 20px;
  }
}
@media (max-width: 400px) {
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block {
    gap: 10px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section {
    gap: 10px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section .subsection {
    gap: 10px;
  }
  .front-page .integration .container .int-block .int-block-container .int-content .int-content-block .section .subsection .subsection-img {
    min-width: 0;
    width: 170px;
  }
}


.front-page .starts {
  padding: 130px 0 110px 0;
  text-align: center;
}
.front-page .starts .ns-title {
  color: #4fae3b;
}
.front-page .starts .s-row {
  max-width: 900px;
  margin: 20px auto 0;
  position: relative;
}
.front-page .starts .s-row .circle {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  margin: auto;
  height: 650px;
  width: 650px;
  text-align: center;
  background: url(./../../assets/landing/big-circ.png) no-repeat center center / contain;
}
.front-page .starts .s-row .circle img {
  max-width: 100%;
}
.front-page .starts .s-row .logo {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 211px;
  height: 60px;
  margin: auto;
}
.front-page .starts .s-row .logo img {
  max-width: 100%;
}
.front-page .starts .s-block .icon {
  margin: 0 0 7px 0;
  position: relative;
}
.front-page .starts .s-block .icon > img {
  max-height: 100%;
  max-width: 100%;
}
.front-page .starts .s-block:not(.sb-4) .icon {
  height: 222px;
}
.front-page .starts .s-block.sb-1,
.front-page .starts .s-block.sb-3 {
  padding: 240px 0 40px 0;
}
.front-page .starts .s-block.sb-4 .allready {
  position: absolute;
  left: -70px;
  bottom: 20px;
}
.front-page .starts .s-block.sb-4 .icon {
  display: inline-block;
}
@media (max-width: 767px) {
  .front-page .starts {
    padding: 70px 0 70px 0;
  }
  .front-page .starts .s-row {
    max-width: 100%;
    margin-left: -10px;
  }
  .front-page .starts .s-row .logo img {
    width: 100px;
  }
  .front-page .starts .s-row .circle {
    height: 510px;
    width: 510px;
  }
  .front-page .starts .s-block.sb-1,
  .front-page .starts .s-block.sb-3 {
    padding: 200px 0 20px 0;
  }
  .front-page .starts .s-block:not(.sb-4) .icon {
    height: 170px;
    margin: 0 -10px 0 -10px;
  }
  .front-page .starts .s-block.sb-4 .allready {
    left: -20px;
    bottom: 60px;
  }
  .front-page .starts .s-block.sb-4 .allready img {
    width: 120px;
  }
}
@media (max-width: 575px) {
  .front-page .starts .container {
    max-width: 320px;
  }
  .front-page .starts .s-row {
    max-width: 100%;
    margin-left: -10px;
  }
  .front-page .starts .s-row .logo img {
    width: 100px;
  }
  .front-page .starts .s-row .circle {
    height: 310px;
    width: 310px;
  }
  .front-page .starts .s-block p {
    font-size: 13px;
  }
  .front-page .starts .s-block.sb-1,
  .front-page .starts .s-block.sb-3 {
    padding: 150px 0 20px 0;
  }
  .front-page .starts .s-block:not(.sb-4) .icon {
    height: 100px;
    margin: 0 -10px 0 -10px;
  }
  .front-page .starts .s-block.sb-4 .allready {
    left: 90px;
    bottom: 100px;
  }
  .front-page .starts .s-block.sb-4 .allready img {
    width: 80px;
  }
}
.front-page .earn {
  padding: 20px 0 55px 0;
  position: relative;
  background: #000;
  z-index: 3;
}
.front-page .earn .for-title {
  margin: 0 0 20px 0;
}
.front-page .earn .for-title .ns-title {
  color: #fff;
}
.front-page .earn .for-title .ns-title span {
  font-weight: 400;
}
.front-page .earn .for-title .min-title {
  color: #4fae3b;
  margin: 0 0 0 20px;
  text-transform: uppercase;
}
.front-page .earn .for-title .min-title span {
  font-weight: 700;
}
.front-page .earn .for-title .min-title p {
  display: inline;
  text-transform: none;
  line-height: 1;
}
.front-page .earn .e-block {
  text-align: center;
}
.front-page .earn .e-block.eb-1,
.front-page .earn .e-block.eb-3 {
  margin: 50px 0 0 0;
}
.front-page .earn .e-block .name {
  color: #fff;
  font-weight: 700;
  margin: -37px 0 0 0;
}
.front-page .earn .e-block hr {
  width: 101px;
  height: 4px;
  background-color: #4fae3b;
  opacity: 0.2;
  margin: 17px auto 18px;
  border: none;
}
.front-page .earn .e-block .text {
  color: #4fae3b;
}
.front-page .earn .e-block .text .min-title {
  font-weight: 700;
  display: inline-block;
}
.front-page .earn .e-block .text .min-title .plain {
  left: -30px;
  top: 10px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .front-page .earn .e-block .text .min-title .plain img {
    width: 240px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .front-page .earn .e-block .text .min-title .plain {
    transform: rotate(35deg);
    top: 80px;
  }
  .front-page .earn .e-block .text .min-title .plain img {
    width: 200px;
  }
}
@media (max-width: 767px) {
  .front-page .earn {
    padding: 15px 0 45px 0;
  }
  .front-page .earn .container {
    max-width: 450px;
  }
  .front-page .earn .for-title .min-title {
    margin: 20px 0 0 0;
  }
  .front-page .earn .for-title .min-title p {
    font-size: 14px;
  }
  .front-page .earn .e-block {
    margin: 30px auto 0 !important;
  }
  .front-page .earn .e-block .icon img {
    width: 100px;
  }
  .front-page .earn .e-block .name {
    margin: -23px 0 0 0;
  }
  .front-page .earn .e-block .text .min-title .plain {
    left: -230px;
    top: 10px;
  }
  .front-page .earn .e-block .text .min-title .plain img {
    width: 210px;
  }
}
@media (max-width: 575px) {
  .front-page .earn .container {
    max-width: 320px;
  }
  .front-page .earn .e-block .text .min-title .plain {
    left: -180px;
    top: 10px;
  }
  .front-page .earn .e-block .text .min-title .plain img {
    width: 230px;
  }
}
.front-page .howmuch {
  padding: 55px 0 20px 0;
  margin-bottom: 122px;
  background: #4fae3b;
  text-align: center;
  position: relative;
  z-index: 2;
}
.front-page .howmuch .for-title {
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0 0 170px;
}
.front-page .howmuch .for-title .min-title {
  margin: 0 auto 30px;
  max-width: 575px;
}
.front-page .howmuch .h-row {
  display: table;
  margin: auto;
  padding: 40px 45px 35px 45px;
  background: #fff;
  border-radius: 100px;
  position: relative;
}
.front-page .howmuch .h-row .sign {
  width: 70px;
  height: 70px;
  background-color: #000000;
  border-radius: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 45px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-family: -webkit-pictograph;
}
.front-page .howmuch .h-row.hr-1 .sign {
  bottom: 0;
  transform: translateY(50%);
}
.front-page .howmuch .h-row.hr-2 .sign {
  top: 0;
  transform: translateY(-50%);
}
.front-page .howmuch .number {
  font-size: 36px;
  text-transform: uppercase;
}
.front-page .howmuch .number span {
  font-weight: 700;
}
.front-page .howmuch .h-block.hbs {
  margin: 62px 0 57px 538px;
}
.front-page .howmuch .h-block.hbs .number {
  color: #fff;
  margin: 0 40px 0 0;
}
.front-page .howmuch .sg {
  text-transform: none;
}
.front-page .howmuch .mtt-1 {
  display: inline-block;
  margin: 35px 0 0 0;
  text-transform: uppercase;
  font-weight: 700;
}
.front-page .howmuch .mtt-1 .arr {
  position: absolute;
  left: 100%;
  top: 5px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .front-page .howmuch .h-block.hbs {
    margin-left: 325px;
  }
  .front-page .howmuch .h-row {
    padding: 40px 15px 45px 15px;
    min-width: 300px;
  }
  .front-page .howmuch .h-row .h-block {
    max-width: 150px;
  }
  .front-page .howmuch .h-row .h-block .number {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .front-page .howmuch {
    padding: 35px 0 20px 0;
    margin-bottom: 77px;
  }
  .front-page .howmuch .h-row {
    padding: 20px 15px 35px 15px;
  }
  .front-page .howmuch .h-row.hr-2 {
    padding-top: 40px;
  }
  .front-page .howmuch .h-row .sign {
    width: 50px;
    height: 50px;
  }
  .front-page .howmuch .h-block.hbs {
    margin: 32px 0 37px 135px;
  }
  .front-page .howmuch .h-block.hbs .number {
    margin: 0 15px 0 0;
  }
  .front-page .howmuch .h-block.hbs p {
    margin: 0 -30px 0 0;
  }
  .front-page .howmuch .number {
    font-size: 25px;
  }
}
@media (max-width: 575px) {
  .front-page .howmuch .container {
    max-width: 320px;
  }
  .front-page .howmuch .for-title {
    padding: 0 0 0 50px;
    max-width: 290px;
    margin: auto;
  }
  .front-page .howmuch .mtt-1 .arr {
    left: 90%;
    top: 15px;
  }
  .front-page .howmuch .mtt-1 .arr img {
    width: 40px;
  }
}

.dotss {
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
  border-radius: 50%;

  -webkit-animation: 2s ease infinite pulse;
  animation: 2s ease infinite pulse;
  box-shadow: 0 0 0;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 0 24px rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 0 24px rgba(255, 255, 255, 0);
  }
}
.input-box {
  display: flex;
  align-items: center;
  position: relative;
}

.input-box .prefix {
  position: absolute;
  font-weight: 300;
  font-size: 14px;
  color: #999;
  left: 8px;
}

.input-box:focus-within {
  border-color: #777;
}
</style>
